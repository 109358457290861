<template>
	<div class="LubokEventView view" v-if="viewIsReady">
		<LuMobileHeader
			class="app__mobileNav"
			v-if="$root.isSmallDevice"
		></LuMobileHeader>

		<div class="grid grid--pageTemplateForEvents hSpace hSpace--page vSpace vSpace--page maxWidth maxWidth--page"
			 :class="{ 'vSpace vSpace--page' : $root.isSmallDevice }" v-if="post">
			<!-- header + aside (both sticky) -->
			<div>
				<LuPageHeader
					:title="$t('title.event')"
				></LuPageHeader>
			</div>
			<!-- content: event -->
			<div :class="{ 'vSpace vSpace--pageBottom' : !$root.isSmallDevice }">
				<LuHeadline
					:text="$root.getTranslatedAcfValue( post.acf, 'postTitle' )"
					:subText="$root.getTranslatedAcfValue( post.acf, 'location' )"
					:hasAnchor="true"
					:anchorTitle="'String'"
					:startDate="post.acf.startDate"
					:endDate="post.acf.endDate"
					:lineAbove="true"
					v-if="post"
				></LuHeadline>

				<template v-if="post">
					<div
						v-for="(contentModule, index) in $root.getContentModules( post )"
						:key="index"
						v-bind="contentModule"
						:is="'Lu' + _.upperFirst(contentModule.acf_fc_layout)"
					></div>
				</template>

				<!--
				-->
				<LuSpacer
					:lines="5"
				></LuSpacer>
			</div>
			<!-- spacer -->
			<div></div>
		</div>

		<LuNotFound v-if="isNotFound"></LuNotFound>

		<!--
		<pre data-name="post">{{post}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '@/mixins/restHandler/v1/restHandler.mixin.js'
	import ViewMixin from './View.mixin.js'
	import MhDevGrid from '@/components/MhDevGrid/MhDevGrid.vue'
	import MhSmartHeader from '@/components/MhSmartHeader/MhSmartHeader.vue'

	import LuMobileHeader from '@/components/LuMobileHeader.vue'
	import LuPageHeader from '@/components/LuPageHeader.vue'
	import LuHeadline from '@/components/LuHeadline.vue'
	import LuText from '@/components/LuText.vue'
	import LuGallery from '@/components/LuGallery.vue'
	import LuSpacer from '@/components/LuSpacer.vue'
	import LuNotFound from '@/components/LuNotFound.vue'

	export default {
		name: 'LubokEventView',
		components: {
			MhDevGrid,
			MhSmartHeader,
			LuMobileHeader,
			LuPageHeader,
			LuHeadline,
			LuText,
			LuGallery,
			LuSpacer,
			LuNotFound,
		},
		mixins: [
			restHandler,
			ViewMixin,
		],
		props: {},
		data() {
			return {
				post       : null,
				isNotFound : false,
			}
		},
		watch: {},
		computed: {},
		methods: {
			doFetch() {
				// send loading to MhView to show the spinner
				EventBus.$emit('stateChanged', 'loading')

				// fetch event by slug
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts/',
					params : {
						slug : this.$route.params.slug,
						postType: 'event_post',
					},
					callback : (response) => {
						const data  = response.data
						const posts = data.result

						this.post = posts[0]
						this.viewIsReady = true // this comes from View.mixin.js

						if( this.post ) {
							this.title = this.$root.getTranslatedAcfValue( this.post.acf, 'postTitle')
						}else{
							this.isNotFound = true
							this.title = ''
						}

						// send loaded to MhView to hide the spinner again
						EventBus.$emit('stateChanged', 'loaded')
					},
				})
			},
		},
		created() {},
		mounted() {
			this.doFetch()
		},
	}
</script>

<style lang="less">
</style>
