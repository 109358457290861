<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<LuNotFound></LuNotFound>

	2019-09-24	init

-->

<template>
	<div class="LuNotFound grid grid--pageTemplateLinear vSpace vSpace--page hSpace hSpace--page maxWidth maxWidth--page">

		<LuPageHeader
			:title="'Error 404'"
		></LuPageHeader>

		<div class="border border--top"></div>

		<LuText
			:text="'Sorry, page not found.'"
			:fontSize="'default'"
			:lineAbove="false"
			:textOnBlack="false"
		></LuText>

	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import LuPageHeader from '@/components/LuPageHeader.vue'
	import LuText from '@/components/LuText.vue'

	export default {
		name: 'LuNotFound',
		components: {
			LuPageHeader,
			LuText,
		},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
		},
		data() {
			return {
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.LuNotFound {
	}

	@media @mediaQuery__xl {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__md {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
</style>
